// import CardValuesHeader from "components/content/card-values-header"
import { cardImage } from "components/survey/utils"
import { Typography } from "components/typography"
import { Link } from "gatsby"
import Product from "models/Product"
import Provider from "models/Provider"
import React from "react"
import { JsonLd } from "react-schemaorg"
import { BreadcrumbList } from "schema-dts"
import AppManager from "services/app-manager"
import { TransactionAccountDisclaimer } from "../../../components/content/content-disclaimer"
import { CreditCard as GetStartedButton } from "../../../components/content/get-started-button"
import { ListHeaderProduct } from "components/content/list-header"
import ProductComponent from "../../../components/content/transaction-accounts/product-component"
import { TransactionAccount as ProductDetails } from "../../../components/content/product-details"
import Layout from "../../../components/layout"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import * as styles from "./style.module.scss"
import CreditCardProduct from "models/product-models/CreditCardProduct"
import CreditCardProvider from "models/provider-models/CreditCardProvider"
import AccountProduct from "models/product-models/AccountProduct"
import TransactionAccountProvider from "models/provider-models/TransactionAccountProvider"
import ReturnLinkChain from "components/content/return-link-chain"
import { CardAds as Ads, SavingsAccountAds } from "components/content/ads"
import FactCheckedDialog from "components/content/fact-checked-dialog"
import { useMediaQuery } from "@mui/material"

const BoldText = ({ children }: { children: any }) => {
  return <span className={styles.boldText}>{children}</span>
}

function makeProductComponent(product: Product, key: number, isLast?: boolean) {
  return (
    <div key={key} style={{ borderBottom: isLast ? "" : "0.5pt #D2D2D2 solid" }}>
      <ProductComponent product={product as AccountProduct} learnMore />
    </div>
  )
}

function makeReviewComponent(reviews: string[], p3Array: { [id: string]: any }[]): JSX.Element {
  return (
    <div>
      {reviews.map((review, key) => (
        <Typography.P style={{ marginBottom: "15px" }}>
          {review}{" "}
          {key > 1
            ? p3Array.map(p3 => (
                <div>
                  <div style={{ fontWeight: "bold", color: "#E7527A" }}>{p3.offerTitle}</div>
                  {p3.offerDescription}
                  {p3.offerDescription !== "" ? (
                    <span
                      style={{ textDecoration: "none", color: "#4f8ccc", cursor: "pointer" }}
                      onClick={() => {
                        DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_GET_OFFER_SELECTED, { offerId: p3.offer.offerId, providerId: p3.offer.providerId, expertReview: true })
                        AppManager.getInstance().getOffer("Go", p3.offer.offerId, p3.offer, p3.offer.providerId, p3.providerName)
                      }}
                    >
                      here.
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ))
            : ""}
        </Typography.P>
      ))}
    </div>
  )
}

const TransactionAccountTemplate = (props: any) => {
  const { providerJson, productId, dateUpdated, ads }: { providerJson: TransactionAccountProvider; productId: string; dateUpdated: string; ads: any } = JSON.parse(props.pageContext.p)
  const provider: Provider = TransactionAccountProvider.Initialise(providerJson)
  const product: AccountProduct = AccountProduct.Initialise(provider.products[productId] as AccountProduct)
  const nameHeading = product.nameLong
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = Number(dateUpdated.substring(8, 10)) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)
  const isWide = useMediaQuery("(min-width:768px)")

  // Expert Review, TODO: refactor after adding in review
  let expertReviewSection: string | JSX.Element = ""

  let breadcrumbName = product.name.replace(provider.name, "") || product.name

  return (
    <Layout title={`${nameHeading} - Review | Stay or Go`} description={product.getMetaDescription()} urlSlug={product.urlSlug ? product.urlSlug : undefined} dateUpdated={dateUpdated} includeMozoDisclaimer>
      <div className="page-container">
        <div className="content-provider-container">
          <ReturnLinkChain
            linkChain={[
              { name: "Bank Accounts", to: "/bank-accounts/" },
              { name: provider.name, to: "/" + provider.urlSlug + "-bank-accounts" },
            ]}
            currentLocationItem={{ name: breadcrumbName }}
            excludeBottomMargin={isWide}
            includeFactCheckedDialog={isWide}
            factCheckedDialogDateUpdated={dateString}
          />
          <div className={styles.heading}>
            <Typography.H1>{nameHeading}</Typography.H1>
          </div>
          {product.forSale === "GRANDFATHERED" ? <div className={styles.subHeading}>(no longer for sale)</div> : null}
          {product.forSale === "PAUSED" ? <div className={styles.subHeading}>(currently not for sale)</div> : null}
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {product.autoDescription}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          {ads ? ads.product.id.includes("SV") ? <SavingsAccountAds ads={ads} /> : null : null}
          <div className="show-for-desktop">
            <ProductComponent product={product} largeCardImage hideName showDisclaimer />
          </div>
          <div className="show-for-mobile">
            <ProductComponent product={product} largeCardImage mobileProduct showDisclaimer />
          </div>
          {/* {product.afFreq === "P1M" || product.rewardsFee !== null || product.directRewardId || product.hasOfferAsterix() ? (
            <Typography.P className={styles.seeDetailsBelow} comp={"flat-18"}>
              * See details below
            </Typography.P>
          ) : null} */}
          {/* {expertReviewSection} */}
          <ListHeaderProduct>Product Details</ListHeaderProduct>
          {product.description !== null ? (
            <div style={{ marginBottom: "30px", marginTop: "30px" }}>
              <div style={{ color: "#797979", fontFamily: "Arboria-Book, sans-serif", fontSize: "16px", display: "flex" }}>
                <img alt="" src="/images/icon_quote.png" style={{ width: "20px", height: "15.66px", marginRight: "15px" }} />
                <div>{product.description}"</div>
              </div>
              <div style={{ color: "#E7527A", fontFamily: "Arboria-Bold, sans-serif", textAlign: "right", fontSize: "16px" }}>- {provider.name}</div>
            </div>
          ) : null}
          <ProductDetails product={product} provider={provider as TransactionAccountProvider} />
          {/* <div className={styles.cardValuesHeader} style={{ marginTop: "60px", marginBottom: "0px", backgroundColor: "#FBFCFC", paddingTop: "11px", paddingBottom: "27px", height: "60px" }}>
            <CardValuesHeader />
          </div> */}
          {/* {provider.getAlternativeProductList(product, makeProductComponent).length > 0 ? (
            // <div className={styles.listHeader} style={{ marginTop: "0px" }}>
            //   <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "22px" }}>Alternative Credit Cards</div>
            // </div>
            <ListHeaderProduct style={{ marginTop: "0px" }}>Alternative Credit Cards</ListHeaderProduct>
          ) : null}
          {provider.getAlternativeProductList(product, makeProductComponent)} */}
          {<TransactionAccountDisclaimer isDisclaimerOpen dateString={dateUpdated} wholeBalanceInterest={product.wholeBalanceRateApplication()} />}
        </div>
      </div>
    </Layout>
  )
}

export default TransactionAccountTemplate
