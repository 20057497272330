// extracted by mini-css-extract-plugin
export var boldText = "style-module--bold-text--0e4fb";
export var cardImage = "style-module--card-image--dfd02";
export var cardImageImg = "style-module--card-image-img--1b19b";
export var cardRow = "style-module--card-row--6e460";
export var cardValuesHeader = "style-module--card-values-header--f763b";
export var description = "style-module--description--f7099";
export var getStarted = "style-module--get-started--2a576";
export var getStartedButton = "style-module--get-started-button--de9c3";
export var heading = "style-module--heading--d5c47";
export var listHeader = "style-module--list-header--47937";
export var seeDetailsBelow = "style-module--see-details-below--a4ea4";
export var subHeading = "style-module--sub-heading--6432b";